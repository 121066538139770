<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Distribution vehicles">
                <delete-items-button v-if="$hasPermission($permissions.RemoveDistributionVehicle)" :selection="selection" @deleted="loadData" @reset=" selection.selected_item_ids = []"/>
                <b-button v-if="$hasPermission($permissions.AddDistributionVehicle)" variant="primary" @click="addDistributionVehicleModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table ref="basicTable" :columns="columns" :data="distributionVehicles" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
                    <div v-if="props.column.displayType === 2 && $hasPermission($permissions.RemoveDistributionVehicle)" class="d-flex justify-content-center">
                        <b-form-checkbox  v-model="selection.selected_item_ids" :value="props.row.id"></b-form-checkbox>
                    </div>
                    <div v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="success" @click="$router.push('/distribution_vehicle/' + props.row.id)" class="mr-1">
                            <feather-icon icon="EyeIcon"/>
                        </b-button>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <b-modal title="Add distribution vehicle" v-model="addDistributionVehicleModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="addObject.name"/>
                </b-form-group>

                <b-form-group>
                    <label>Type</label>
                    <b-form-input v-model="addObject.type"/>
                </b-form-group>

                <b-form-group>
                    <label>Registration</label>
                    <b-form-input v-model="addObject.registration"/>
                </b-form-group>

                <b-form-group>
                    <label>Warehouses</label>
                    <v-select
                        v-model="addObject.assigned_warehouses"
                        :reduce="type => type.id"
                        label="name"
                        multiple
                        :options="warehouses"
                    />
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addDistributionVehicleModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addDistributionVehicle">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BButton, BCard, BFormGroup, BFormInput, BModal, BOverlay, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'

    export default {
        components: {
            DeleteItemsButton,
            BCard,
            vSelect,
            BOverlay,
            BasicTable,
            BButton,
            BFormGroup,
            BFormInput,
            BModal,
            BFormCheckbox
        },
        data() {
            return {
                dataLoaded: false,
                distributionVehiclesRaw: [],
                warehouses: [],
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },
                columns: [
                    {
                        label: 'Select',
                        displayType: 2,
                        field: 'select',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Type',
                        displayType: 0,
                        field: 'type',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Registration',
                        displayType: 0,
                        field: 'registration',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Warehouses',
                        displayType: 0,
                        field: 'warehouses',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addDistributionVehicleModalActive: false,
                addObject: {
                    name: '',
                    type: '',
                    registration: '',
                    warehouses: []
                }
            }
        },
        methods: {
            openDistributionVehicleEdit(object) {
                this.editObject = JSON.parse(JSON.stringify(object))
                this.editDistributionVehicleModalActive = true
            },
            loadData() {
                this.$refs.basicTable.$refs.table.reset()
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/distribution_vehicle')
                loadPromise.then(function(response) {
                    thisIns.distributionVehiclesRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const warehousesLoadPromise = this.$http.get('/api/management/v1/warehouse')
                warehousesLoadPromise.then(function(response) {
                    thisIns.warehouses = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, warehousesLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addDistributionVehicle() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/distribution_vehicle', this.addObject)
                addPromise.then(function() {

                    thisIns.$printSuccess('Distribution vehicle added')

                    thisIns.addDistributionVehicleModalActive = false
                    thisIns.addObject = {
                        name: '',
                        type: '',
                        registration: ''
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }

        },
        computed: {
            distributionVehicles() {
                return this.distributionVehiclesRaw.map((vehicle, index) => {
                    return {
                        index,
                        id: vehicle.id,
                        name: vehicle.name,
                        type: vehicle.type,
                        registration: vehicle.registration,
                        warehouses: vehicle.assigned_warehouse_names.join(', ')
                    }
                })
            }
        },
        mounted() {
            this.selection.collection_name = 'distribution_vehicles'
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>